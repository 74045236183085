import React, { useContext } from "react";
import Seo from "../components/Seo/Seo";
import G from "../images/groenhout_g.png"
import Container from "../components/Container/Container";
import { Link, useStaticQuery, graphql } from "gatsby";
import VestigingContext from "../store/vestiging-context";
import { motion } from "framer-motion";
import algemeenVerkopen from "../images/huis-verkopen.jpg"
import algemeenKopen from "../images/richard-verkoop.jpg"
import algemeenTaxeren from "../images/arthur-taxatie.jpg"
import { faArrowRight, faHome } from "@fortawesome/free-solid-svg-icons";
import CtaButton from "../components/CtaButton/cta"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import Testimonial from "../components/Testimonials/Testimonials";

const DienstenPage = () => {
    const ctx = useContext(VestigingContext)
    const vestiging = ctx.vestiging
    const data = useStaticQuery(
        graphql`
        query DienstenPage {
            wpPage(slug: {eq: "diensten"}) {
                seo{
                    metaDesc
                    title
                  }
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
        } `
      )

      const imgVerkoop = algemeenVerkopen
      const imgKopen = algemeenKopen
      const imgTaxeren = algemeenTaxeren
      const Image = data.wpPage.featuredImage?.node.mediaItemUrl

    return(
        <>
            <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc}></Seo>
            <SmallHeroNew title="Onze diensten"overlay={true} overlayImage={G} image={Image} ></SmallHeroNew>
            <Container styles={`lg:my-16 my-4 flex lg:flex-row flex-col items-center`}>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: .3, delay: 0 }} className="w-full lg:w-3/6 mt-10 lg:mt-0 flex flex-col justify-center">
                        <h2 className="text-blue text-4xl font-black leading-snug w-10/12 lg:w-full lg:mx-0 font-black">Dé makelaar in {vestiging !== undefined ? vestiging : `Assen, Beilen en Hoogeveen`}</h2>
                        <div>
                            <p className="text-gray lg:mx-0 w-10/12">Als moderne makelaar zijn wij veelzijdig in de diensten die wij aan jou kunnen leveren.</p>
                        </div>
                    </motion.div>
                    <motion.div initial={{ translateY: -100, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: .3, delay: .15 }} className="w-full lg:w-6/12 lg:mx-0 lg:mt-0 flex lg:justify-end justify-center">
                        <div className="lg:w-10/12 w-full lg:mx-0 shadow-lg">
                            <div className="p-10 bg-white grid gap-5">
                                <CtaButton target={'/dienst/aankoop'} title={'Een woning kopen'} icon={faArrowRight}></CtaButton>
                                <CtaButton target={'/dienst/verkoop'} title={'Een woning verkopen'} icon={faArrowRight}></CtaButton>
                                <CtaButton target={'/dienst/taxatie'} title={'Mijn woning laten taxeren'} icon={faArrowRight}></CtaButton>
                            </div>
                        </div>
                    </motion.div>
            </Container>
            <div className='bg-light relative'>
                <Container>
                    <div className="w-full py-16 lg:pr-24 lg:px-0 px-5">
                    <h2 className="text-3xl text-blue w-full lg:w-2/5">Ik wil een huis kopen</h2>
                    <p  className="w-full lg:w-1/2 lg:pb-0 pb-4 lg:pr-4">
                    Groenhout Makelaars is een innoverend en vakkundig makelaarskantoor actief in heel Drenthe met een team van ervaren professionals die altijd gaan voor het beste resultaat. Bij ons vindt u een specialist op ieder gebied als het gaat om wonen. Of u nu een huis wilt verkopen, aankopen of een taxatie nodig bent, met ons team aan specialisten bent u op het juiste adres.
                    </p>
                    <div className="block w-full mx-auto lg:mx-0 lg:w-1/3"><CtaButton target={'/dienst/aankoop'} title="Een woning kopen" icon={faArrowRight}></CtaButton></div>
                    </div>
                    <img src={imgKopen} className={'object-cover object-center h-96 lg:h-full w-full lg:w-1/2 relative lg:absolute lg:right-0 lg:top-0'}></img>
                </Container>
            </div>
            <div className='bg-light relative'>
                <Container styles={`flex justify-end`}>
                    <div className="w-1/2 py-16 lg:px-0 lg:pl-24 px-5">
                    <h2 className="text-3xl text-blue w-full">Ik wil mijn huis verkopen</h2>
                    <p  className="w-full lg:pb-0 pb-4 lg:pr-4">
                    Groenhout Makelaars is een innoverend en vakkundig makelaarskantoor actief in heel Drenthe met een team van ervaren professionals die altijd gaan voor het beste resultaat. Bij ons vindt u een specialist op ieder gebied als het gaat om wonen. Of u nu een huis wilt verkopen, aankopen of een taxatie nodig bent, met ons team aan specialisten bent u op het juiste adres.
                    </p>
                    <div className="block w-full lg:w-2/3 mx-auto lg:mx-0"><CtaButton target={'/dienst/verkoop'} title="Mijn woning verkopen" icon={faArrowRight}></CtaButton></div>
                    </div>
                    <img src={imgVerkoop} className={'object-cover h-96 lg:h-full w-full lg:w-1/2 relative lg:absolute lg:left-0 lg:top-0'}></img>
                </Container>
            </div>
            <div className='bg-light relative'>
                <Container>
                    <div className="w-full lg:pr-24 py-16 lg:px-0 px-5">
                    <h2 className="text-3xl text-blue w-full lg:w-2/5">Ik wil mijn huis laten taxeren</h2>
                    <p  className="w-full lg:w-1/2 lg:pb-0 pb-4 lg:pr-4">
                    Groenhout Makelaars is een innoverend en vakkundig makelaarskantoor actief in heel Drenthe met een team van ervaren professionals die altijd gaan voor het beste resultaat. Bij ons vindt u een specialist op ieder gebied als het gaat om wonen. Of u nu een huis wilt verkopen, aankopen of een taxatie nodig bent, met ons team aan specialisten bent u op het juiste adres.
                    </p>
                    <div className="block w-full mx-auto lg:mx-0 lg:w-1/3"><CtaButton target={'/dienst/taxatie'} title="Een woning laten taxeren" icon={faArrowRight}></CtaButton></div>
                    </div>
                    <img src={imgTaxeren} className={'object-cover h-96 lg:h-full w-full lg:w-1/2 relative lg:absolute lg:right-0 lg:top-0'}></img>
                </Container>
            </div>
            <Testimonial></Testimonial>
        </>
    )
}
export default DienstenPage